import TablePage from '../../../../components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {},
    };
  },
  created() {
    this.getConfigList('mdm_log_page_list');
  },
  methods: {

  },
};
